<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->


<template>
  <div>
    <CModal
      :show.sync='isShowModal'
      :closeOnBackdrop='false'
    >
      <div slot='header-wrapper'>
        <header class='modal-header'>
          <div slot='header' class='header-wrapper modal-flex-current'>
            <h5 class='modal-title'>{{$t('smart.modalParamTitle')}}</h5>
            <CButtonClose @click='closeModal' />
          </div>
        </header>
      </div>
      <CSelect
        label='Orderer*'
        :options='ordererForSelect'
        horizontal
        v-if='type !== "install"'
        required
        placeholder=' '
        addLabelClasses='font-weight-bold'
        :value.sync='form.orderer'
        :isValid='isValid.orderer'
        :tooltipFeedback=false
        :invalidFeedback='validationMessages.required'
        @change='$v.form.orderer.$touch()'
      />

      <CSelect
        label='Fabric-admin-tool*'
        :options='fabricAdminToolForSelect'
        horizontal
        required
        placeholder=' '
        addLabelClasses='font-weight-bold'
        :value.sync='form.fabricAdminTool'
        :isValid='isValid.fabricAdminTool'
        :tooltipFeedback=false
        :invalidFeedback='validationMessages.required'
        @change='$v.form.fabricAdminTool.$touch()'
      />

      <CSelect
        label='Peer*'
        :options='peerForSelect'
        horizontal
        required
        placeholder=' '
        addLabelClasses='font-weight-bold'
        :value.sync='form.peer'
        :isValid='isValid.peer'
        :tooltipFeedback=false
        :invalidFeedback='validationMessages.required'
        @change='$v.form.peer.$touch()'
      />

      <div slot='footer' class='footer-buttons'>
        <CButton
          type='button'
          class='btn btn-secondary'
          @click='closeModal'
        > {{ $t('common.cancel') }}
        </CButton>
        <CButton
          type='button'
          class='btn btn-primary'
          :disabled='!isFormValid'
          @click='createParam'
        > {{ $t('common.create') }}
        </CButton>
      </div>
    </CModal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import messages from '../messages';

export default {
  name: 'ApproveChainCodeModal',
  components: {},
  props: {
    isShowModal: Boolean,
    ordererList: Array,
    fabricAdminToolList: Array,
    peerList: Array,
    id: String,
    type: String
  },

  data() {
    return {
      validationMessages: messages.validation,
      form: {
        orderer: null,
        fabricAdminTool: null,
        peer: null
      }
    };
  },

  validations: {
    form: {
      orderer: {
        required
      },
      fabricAdminTool: {
        required
      },
      peer: {
        required
      }
    }
  },

  computed: {
    isValid() {
      let orderer, fabricAdminTool, peer;

      if (this.$v.form.orderer.$dirty && this.type !== 'install')
        orderer = !this.$v.form.orderer.$invalid;

      // todo error need to fix here fabricAdminTool instead orderer
      if (this.$v.form.fabricAdminTool.$dirty)
        orderer = !this.$v.form.fabricAdminTool.$invalid;

      if (this.$v.form.peer.$dirty)
        orderer = !this.$v.form.peer.$invalid;

      return {
        orderer: this.type !== 'install' ? orderer : true,
        fabricAdminTool,
        peer
      };
    },

    isFormValid() {
      return this.form.peer && this.form.fabricAdminTool
        && (this.type === 'install' ? true : this.form.orderer);
    },

    ordererForSelect() {
      return this.ordererList?.length > 0 ? this.ordererList.map(item => {
        return {
          label: item.hostname,
          value: item.id
        };
      }) : [];
    },

    fabricAdminToolForSelect() {
      return this.fabricAdminToolList?.length > 0 ? this.fabricAdminToolList.map(item => {
        return {
          label: item.hostname,
          value: item.id
        };
      }) : [];
    },

    peerForSelect() {
      return this.peerList?.length > 0 ? this.peerList.map(item => {
        return {
          label: item.hostname,
          value: item.id
        };
      }) : [];
    }

  },

  methods: {
    closeModal() {
      this.form = {
        orderer: null,
        fabricAdminTool: null,
        peer: null
      };
      this.$v.$reset();
      this.$emit('closeModal');
    },

    async createParam() {
      const data = {
        orderer_endpoint_id: this.form.orderer,
        admin_tool_endpoint_id: this.form.fabricAdminTool,
        peer_endpoint_id: this.form.peer,
        smart_contract_instance_id: this.id,
        type: this.type
      };
      this.$emit('createParam', data);
      this.closeModal();
    }

  }
};
</script>

<style scoped>
.footer-buttons button {
  margin-left: 5px;
}

.modal-flex-current {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

</style>
