<template>
  <div class="rule-constructor w-100">
    <div
      v-if="!organizations || organizations.length === 0"
      class="empty-state"
    >
      <p class="empty-state__message">{{$t('smart.selectChannel')}}</p>
    </div>
    <conditions-group
      v-else
      @deleteCondition="({ group, index }) => deleteCondition(group, index)"
      @addCondition="(group) => addCondition(group)"
      @addChild="(group) => addChild(group)"
      @changed="() => $emit('changed')"
      :organizations="organizations"
      :group="value"
      :isColored="false"
    />
  </div>
</template>

<script>
import ConditionsGroup from "./ConditionsGroup";

export default {
  name: "RuleConstructor",
  components: {
    ConditionsGroup,
  },
  props: {
    organizations: Array,
    value: Object
  },
  data() {
    return {
      logicalOperators: [
        {
          label: "OR",
          value: "OR",
        },
        {
          label: "AND",
          value: "AND",
        },
      ],
      
    };
  },
  computed: {},
  methods: {
    addCondition(group) {
      group.conditions.push("");
      this.$emit('changed');
    },
    deleteCondition(group, index) {
      const conditions = group.conditions;
      conditions.splice(index, 1);
      group.conditions = conditions;
      this.$emit('changed');
    },
    addChild(group) {
      group.children.push({
        operator: "OR",
        conditions: [""],
        children: [],
      });
      this.$emit('changed');
    }
  },
};
</script>

<style scoped>
.rule-constructor__action {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.add-group-button {
  border: 1px solid #dedede;
}

.no-margin-no-padding {
  margin: 0;
  padding: 0;
}
.operator-selector {
  width: 130px;
}

.groups {
}

.group {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #dedede;
  display: flex;
  flex-direction: column;
  padding: 7px;

  position: relative;
}

.group:not(:last-child) {
  margin-bottom: 7px;
}

.add-condition-button {
  width: 160px;
}

.add-participant-button {
  width: 180px;
}

.condition:not(:last-child) {
  margin-bottom: 7px;
}

.condition-selector {
  flex: 1 0 20%;
}

.empty-state {
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px dashed #dedede;
  border-radius: 5px;
  padding: 20px 0;
}

.empty-state__message {
  padding: 0;
  margin: 0;
  color: #8f8f8f;
}
</style>
