<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->
<template>
  <div class="commit-smart-contract-wrapper">
    <CButton
      v-if="item.item.isCommitChainCodeLoading"
      size="sm"
      class="p-0 m-3 mr-icon"
      @click="goToTopic(commitChainCodeTopicId)"
    >
      <fa-icon icon="sync" size="lg" class="animated" />
    </CButton>

    <CButton
      v-else
      size="sm"
      class="p-0 m-3 mr-icon"
      @click="commitChainCodeModal(item)"
      v-c-tooltip="{
        visible: true,
        content: $t('smart.startCommitSmart'),
        placement: 'left',
        container: 'body',
      }"
    >
      <fa-icon icon="rocket" size="lg" />
    </CButton>

    <CModal :show.sync="isShowModal" :closeOnBackdrop="false">
      <div slot="header-wrapper">
        <header class="modal-header">
          <div slot="header" class="header-wrapper modal-flex-current">
            <h5 class="modal-title">{{ $t("smart.modalParamTitle") }}</h5>
            <CButtonClose @click="closeModal" />
          </div>
        </header>
      </div>

      <div class="hauberk-form-group">
        <div class="hauberk-form-item hb-item hb-item_small d-flex">
          <div class="hb-item__label hb-label d-flex flex-column">
            <label class="hb-label__main" for="orderer">Orderer*</label>
          </div>

          <CSelect
            id="orderer"
            class="hb-item__input"
            :options="ordererForSelect"
            :horizontal="{ label: 'col-sm-5', input: 'd-flex w-100' }"
            v-if="type !== 'install'"
            required
            placeholder=" "
            addLabelClasses="font-weight-bold"
            :value.sync="form.orderer"
            :isValid="isValid.orderer"
            :tooltipFeedback="false"
            :invalidFeedback="validationMessages.required"
            @change="$v.form.orderer.$touch()"
          />
        </div>
        <div class="hauberk-form-item hb-item hb-item_small d-flex">
          <div class="hb-item__label hb-label d-flex flex-column">
            <label class="hb-label__main" for="fabricAdminTool"
              >Fabric-admin-tool*</label
            >
          </div>

          <CSelect
            id="fabricAdminTool"
            class="hb-item__input"
            :options="fabricAdminToolForSelect"
            :horizontal="{ label: 'col-sm-5', input: 'd-flex w-100' }"
            required
            placeholder=" "
            addLabelClasses="font-weight-bold"
            :value.sync="form.fabricAdminTool"
            :isValid="isValid.fabricAdminTool"
            :tooltipFeedback="false"
            :invalidFeedback="validationMessages.required"
            @change="$v.form.fabricAdminTool.$touch()"
          />
        </div>
        <div class="hauberk-form-item hb-item hb-item_small d-flex">
          <div class="hb-item__label hb-label d-flex flex-column">
            <label class="hb-label__main" for="peers">Peers*</label>
          </div>

          <div class="hb-item__input">
            <hauberk-select
              id="peers"
              :options="peerForSelect"
              v-model="form.peers"
            >
            </hauberk-select>
          </div>
        </div>
      </div>

      <div slot="footer" class="footer-buttons">
        <CButton type="button" class="btn btn-secondary" @click="closeModal">
          {{ $t("common.cancel") }}
        </CButton>
        <CButton
          type="button"
          class="btn btn-primary"
          :disabled="!isFormValid"
          @click="commitChainCodeRequest"
        >
          {{ $t("common.create") }}
        </CButton>
      </div>
    </CModal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import messages from "../messages";
import jwt_decode from "jwt-decode";
import utilMessages from "@/utils/messages";
import HauberkSelect from "../common/HauberkSelect.vue";

export default {
  name: "CommitModal",
  components: { HauberkSelect },
  props: {
    item: Object,
    id: String,
    type: String,
  },
  data() {
    return {
      isShowModal: false,
      validationMessages: messages.validation,
      ordererList: [],
      fabricAdminToolList: [],
      peerList: [],
      commitChainCodeTopicId: null,
      form: {
        orderer: null,
        fabricAdminTool: null,
        peers: [],
      },
      selectedPeers: [],
    };
  },
  validations: {
    form: {
      orderer: {
        required,
      },
      fabricAdminTool: {
        required,
      },
      peer: {
        required,
      },
    },
  },
  computed: {
    isValid() {
      let orderer, fabricAdminTool;

      if (this.$v.form.orderer.$dirty && this.type !== "install") {
        orderer = !this.$v.form.orderer.$invalid;
      }

      if (this.$v.form.fabricAdminTool.$dirty) {
        fabricAdminTool = !this.$v.form.fabricAdminTool.$invalid;
      }

      return {
        orderer: this.type !== "install" ? orderer : true,
        fabricAdminTool,
      };
    },
    isFormValid() {
      return (
        this.form.peers?.length > 0 &&
        this.form.fabricAdminTool &&
        (this.type === "install" ? true : this.form.orderer)
      );
    },
    ordererForSelect() {
      return this.ordererList?.length > 0
        ? this.ordererList.map((item) => {
            return {
              label: item.hostname,
              value: item.id,
            };
          })
        : [];
    },
    fabricAdminToolForSelect() {
      return this.fabricAdminToolList?.length > 0
        ? this.fabricAdminToolList.map((item) => {
            return {
              label: item.hostname,
              value: item.id,
            };
          })
        : [];
    },
    peerForSelect() {
      return this.peerList?.length > 0
        ? this.peerList.map((item) => {
            return {
              label: item.hostname,
              value: item.id,
            };
          })
        : [];
    },
  },
  methods: {
    closeModal() {
      this.form = {
        orderer: null,
        fabricAdminTool: null,
        peer: null,
      };
      this.$v.$reset();
      this.isShowModal = false;
    },
    async commitChainCodeModal(item) {
      await this.getBlockChainId();
      await this.getFabricAdminTool();
      await this.getOrderer();
      await this.getPeer();
      this.isShowModal = true;
    },
    async commitChainCodeRequest() {
      const data = {
        packageId: this.item.item.smart_contract_package_id,
        vm: this,
        smart_contract_instance_id: this.item.item.id,
        admin_tool_endpoint_id: this.form.fabricAdminTool,
        orderer_endpoint_id: this.form.orderer,
        endorsers_ids: this.form.peers,
      };
      this.commitChainCodeTopicId = await this.$store.dispatch(
        "commitChaincode",
        data
      );
      this.closeModal();
    },
    goToTopic(topic_id) {
      window.open(`/topics/${topic_id}`);
    },
    async getBlockChainId() {
      if (!this.bNetID) {
        const token = localStorage.getItem("token");
        const decodeToken = jwt_decode(token);
        const { blockchain_net_id } = decodeToken;
        this.bNetID = blockchain_net_id;
      }
    },
    async getOrderer() {
      try {
        const param = `blockchain_net_id=${this.bNetID}&type=orderer`;
        this.ordererList = await this.$store.dispatch("fetchEndpoints", param);
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async getFabricAdminTool() {
      try {
        const param = `status=attached&current_org=true&type=fabric-admin-tools`;
        this.fabricAdminToolList = await this.$store.dispatch(
          "fetchEndpoints",
          param
        );
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async getPeer() {
      try {
        const param = `blockchain_net_id=${this.bNetID}&type=peer`;
        this.peerList = await this.$store.dispatch("fetchEndpoints", param);
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
  },
};
</script>

<style scoped>
.footer-buttons button {
  margin-left: 5px;
}

.modal-flex-current {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.doubleSelectList,
.buttonRight {
  display: flex;
  justify-content: flex-end;
}

.btn-color-link {
  color: blue;
}

.mr-icon {
  margin-right: 10px !important;
  position: relative;
}

.relativeClass {
  position: relative;
}
</style>
