<template>
  <div class="condition d-flex">
    <CSelect
      :options="items"
      placeholder=" "
      class="no-margin-no-padding condition-selector"
      @update="() => $emit('change')"
      @input="$emit('input', $event?.target?.value)"
    />
    <CButton size="sm" class="ml-1" @click="deleteItem()">
      <CIcon name="cil-trash" size="sm" />
    </CButton>
  </div>
</template>

<script>
export default {
  name: "Condition",
  props: {
    value: String,
    items: Array,
  },
  methods: {
    deleteItem() {
      this.$emit("deleteCondition");
    },
  },
};
</script>

<style>
.condition:not(:last-child) {
  margin-bottom: 7px;
}

.no-margin-no-padding {
  margin: 0;
  padding: 0;
}

.condition-selector {
  flex: 1 0 60%;
}
</style>
