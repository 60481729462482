/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/

import i18n from '../../../i18n'

export const SmartContractStatus = {
  success: {code: 'success', text: i18n.t('smart.smartConst[0]'), style: 'success'},
  error: {code: 'error', text: i18n.t('smart.smartConst[1]'), style: 'danger'},
  requested: {code: 'requested', text: i18n.t('smart.smartConst[2]'), style: 'warning'},
  draft: {code: 'draft', text: i18n.t('smart.smartConst[3]'), style: 'secondary'},
}

export const Languages = {
  golang: {code: 'golang', text: 'Go'},
  java: {code: 'java', text: 'Java'},
  node: {code: 'node', text: 'Node'},
}
