<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <div>
    <CButton variant="outline" color="dark" @click="showSignatureModal">
      {{ item.item.signature_policy }}
    </CButton>
    <CModal
      title=""
      :show.sync="isShowModal"
      :closeOnBackdrop="false"
      class="hide-close-button"
      size="xl"
    >
      <div slot="header-wrapper">
        <header class="modal-header">
          <div slot="header" class="header-wrapper modal-flex-current">
            <h5 class="modal-title">{{ $t("smart.reviewExample") }}</h5>
            <CButtonClose @click="closeModal" />
          </div>
        </header>
      </div>
      <div class="bodyWrapper" slot="body-wrapper">
        <CInput
          horizontal
          :label="$t('smart.channel')"
          class="mb-1"
          disabled="true"
          :value.sync="item.item.channel_name"
        />

        <CInput
          horizontal
          :label="$t('smart.initParams')"
          class="mb-1"
          disabled="true"
          :value.sync="item.item.channel_name"
        />

        <CInput
          horizontal
          :label="$t('smart.pdcParams')"
          class="mb-1"
          disabled="true"
          :value.sync="item.item.channel_name"
        />

        <CInput
          horizontal
          :label="$t('smart.politics')"
          class="mb-1"
          disabled="true"
          :value.sync="item.item.signature_policy"
        />

        <CRow class="mb-5">
          <CCol sm="3" />
          <CCol sm="9">
            <img
              v-if="imageSchemeUrl !== ''"
              :src="imageSchemeUrl"
              imageRendering="pixelated"
            />
          </CCol>
        </CRow>
      </div>

      <div slot="footer" class="footer-buttons">
        <CButton type="button" class="btn btn-secondary" @click="closeModal">
          {{ $t("common.cancel") }}
        </CButton>
      </div>
    </CModal>
  </div>
</template>

<script>
import plantumlEncoder from "plantuml-encoder";

export default {
  name: "ViewSignatureModal",

  props: {
    item: Object,
  },

  data() {
    return {
      isShowModal: false,
      isFormValid: false,
      imageSchemeUrl: "",
    };
  },

  methods: {
    closeModal() {
      this.isShowModal = false;
    },
    async showSignatureModal() {
      this.showPlantUml();
      this.isShowModal = true;
    },
    async showPlantUml() {
      const str = this.item.item.signature_policy;

      const regexps = [
        { expression: /\(/g, replaceWith: ":{" },
        { expression: /\)/g, replaceWith: "}" },
        { expression: /'/g, replaceWith: '"' },
        { expression: /.peer"/g, replaceWith: '_peer": ""' },
        { expression: /OR/g, replaceWith: '"OR"' },
        { expression: /AND/g, replaceWith: '"AND"' },
      ];

      const processedStr = regexps.reduce(
        (prev, item) => prev.replace(item.expression, item.replaceWith),
        str
      );
      const encoded = plantumlEncoder.encode(
        `@startjson\n{${processedStr}}\n@endjson`
      );
      const umlResponse = await this.$store.dispatch(
        "getUmlForEndorsementView",
        encoded
      );

      if (!umlResponse) {
        return;
      }

      try {
        let blobImg = new Blob([umlResponse]);
        this.imageSchemeUrl = (window.URL || window.webkitURL).createObjectURL(
          blobImg
        );
      } catch (e) {
        console.log(e);
      }
    },
    getDiagramImage(img) {
      return img.replace("/uml/", "/png/");
    },
  },
};
</script>

<style scoped>
.footer-buttons button {
  margin-left: 5px;
}

.bodyWrapper {
  overflow: auto;
  padding: 20px;
}

.doubleSelectList > div {
  width: 100%;
}

.wallpaper svg {
  margin-right: 5px;
}

.modal-flex-current {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
</style>
