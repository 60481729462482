<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->


<template>
  <div class='inlineBox'>
    <CButton
      variant='outline'
      color='dark'
      class='m-3 opacityBtn'
      v-if='isImported'
      v-c-tooltip="{
          visible: true,
          content: $t('smart.noSourceCode'),
          placement: 'top'
        }"
    >
      <fa-icon icon='wrench' size='lg' />
      {{ $t('smart.buildPackage') }}
    </CButton>
    <CButton
      variant='outline'
      color='dark'
      class='m-3'
      @click='buildPackage'
      v-else
    >
      <fa-icon v-if='isBuildLoading' class='animated' icon='sync' />
      <fa-icon v-else icon='wrench' size='lg' />
      {{ $t('smart.buildPackage') }}
    </CButton>
    <CModal
      :title="$t('smart.addPackage')"
      :show.sync='isShowModal'
      :closeOnBackdrop='false'
      class='hide-close-button'
    >
      <CInput
        :label="$t('smart.programName')"
        horizontal
        v-model='form.code_name'
        :invalidFeedback='validationMessages.codeName'
        :tooltipFeedback=false
        :isValid='isValid.code_name'
        @change='$v.form.code_name.$touch()'
      />
      <CInput
        :label="$t('smart.version')"
        horizontal
        v-model='form.version'
        :invalidFeedback='validationMessages.required'
        :tooltipFeedback=false
        :isValid='isValid.version'
        @change='$v.form.version.$touch()'
      />

      <div slot='footer' class='footer-buttons'>
        <CButton
          type='button'
          class='btn btn-secondary'
          @click='closeModal'
        > {{ $t('common.cancel') }}
        </CButton>
        <CButton
          type='button'
          class='btn btn-primary'
          :disabled='!isFormValid'
          @click='createParam'
        > {{ $t('common.send') }}
        </CButton>
      </div>
    </CModal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import messages from '../messages';
import { mapGetters } from 'vuex';
import { isValidCodeName } from '@/utils/validators';

export default {
  name: 'CreateBuildModal',

  props: {
    isImported: Boolean
  },

  data() {
    return {
      validationMessages: messages.validation,
      form: {
        code_name: '',
        version: ''
      },
      isShowModal: false
    };
  },
  validations: {
    form: {
      code_name: {
        required,
        isValidCodeName
      },
      version: {
        required
      }
    }
  },

  computed: {
    ...mapGetters(['getBuildStatus']),

    isBuildLoading() {
      return this.getBuildStatus;
    },
    isValid() {
      let code_name, version;

      if (this.$v.form.code_name.$dirty)
        code_name = !this.$v.form.code_name.$invalid;

      if (this.$v.form.version.$dirty)
        version = !this.$v.form.version.$invalid;

      return {
        code_name,
        version
      };
    },

    isFormValid() {
      return !this.$v.form.$invalid;
    }
  },

  methods: {
    closeModal() {
      this.form = {
        code_name: '',
        version: ''
      };
      // this.$v.$reset()
      this.isShowModal = false;
    },

    createParam() {
      const data = {
        code_name: this.form.code_name,
        version: this.form.version,
        id: this.$route.params.id,
        vm: this
      };
      this.$store.dispatch('createBuild', data);
      this.closeModal();
    },
    buildPackage() {
      this.isShowModal = true;
    }

  }
};
</script>

<style scoped>
.footer-buttons button {
  margin-left: 5px;
}

.inlineBox {
  display: inline-block;
}

.animated {
  animation: spin 2s linear infinite;
}

.opacityBtn {
  opacity: .65;
}

.opacityBtn:hover {
  background: transparent;
  color: #636f83;
}

@keyframes spin {
  100% {
    transform: rotate(360deg)
  }
}

</style>
