<template>
  <div v-bind:class="{ colored: isColored }" class="group">
    <CButtonClose
      @click="() => $emit('deleteGroup', group)"
      class="close-button"
    />

    <div class="group__actions mb-2">
      <CSelect
        class="no-margin-no-padding mr-2"
        :options="logicalOperators"
        v-model="group.operator"
        @change="handleChange"
        required
        placeholder=" "
      />
      <CButton
        class="add-participant-button mr-2 mt-1"
        @click="() => $emit('addCondition', group)"
        variant="ghost"
        color="primary"
        >+ {{$t('smart.addParticipant')}}</CButton
      >
      <CButton
        class="add-condition-button mt-1"
        variant="ghost"
        color="primary"
        @click="() => $emit('addChild', group)"
        >+ {{$t('smart.addCondition')}}</CButton
      >
    </div>

    <div
      class="condition d-flex"
      v-for="(condition, index) in group.conditions"
    >
      <CSelect
        :options="organizations"
        placeholder=" "
        class="no-margin-no-padding condition-selector"
        v-model="group.conditions[index]"
        @change="handleChange"
      />
      <CButton
        size="sm"
        class="ml-1"
        @click="() => $emit('deleteCondition', { group, index })"
      >
        <CIcon name="cil-trash" size="sm" />
      </CButton>
    </div>

    <div class="children" v-if="group.children && group.children.length > 0">
      <conditions-group
        v-for="(child, groupIndex) in group.children"
        :name="`child-${groupIndex}`"
        @deleteCondition="
          ({ group, index }) => $emit('deleteCondition', { group, index })
        "
        @addCondition="(group) => $emit('addCondition', group)"
        @addChild="(group) => $emit('addChild', group)"
        @deleteGroup="(group) => deleteGroup(group)"
        @changed="() => $emit('changed')"
        :organizations="organizations"
        :group="child"
        :key="groupIndex"
        :isColored="!isColored"
      />
    </div>
  </div>
</template>

<script>
import Condition from "./Condition.vue";

export default {
  name: "ConditionsGroup",
  components: {
    Condition,
  },
  props: {
    group: Object,
    organizations: Array,
    isColored: Boolean,
  },
  data: function () {
    return {
      logicalOperators: [
        {
          label: "OR",
          value: "OR",
        },
        {
          label: "AND",
          value: "AND",
        },
      ],
    };
  },
  mounted() {
    this.$emit("changed");
  },
  methods: {
    deleteGroup(group) {
      const index = this.group.children.findIndex((item) => item === group);

      if (index < 0) {
        return;
      }

      this.group.children.splice(index, 1);
      this.$emit("changed");
    },
    handleChange() {
      this.$emit("changed");
    },
  },
};
</script>

<style>
.group {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #dedede;
  display: flex;
  flex-direction: column;
  padding: 7px;

  position: relative;
}

.group:not(:last-child) {
  margin-bottom: 7px;
}

.add-condition-button {
  width: 160px;
}

.add-participant-button {
  width: 180px;
}

.flex-20 {
  flex: 1 0 20%;
}

.group__actions {
  display: flex;
  align-items: center;
}

.colored {
  background-color: rgb(243 245 250);
}

.close-button {
  position: absolute;
  top: 5px;
  right: 9px;
}
</style>
