<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <div class="mt-2">
    <CCard class="mb-2" v-for="(item, i) in list" :key="item.id">
      <CCardHeader
        class="border-bottom-0 collapse_item_header"
        @click="accordeonHandler(i, item.id)"
      >
        <template v-if="isKnown">
          <strong class="mr-2">{{ item.code_name }}</strong>
          <span class="mr-2">{{ $t("smart.ver") }} {{ item.version }}, </span>
          <span class="mr-2"
            >{{ $t("smart.buildDate") }}
            {{ getDateFormatted(item.build_datetime) }},
          </span>
          <span class="mr-2"
            >{{ $t("smart.hash") }}
            {{ item.package_hash.substring(0, 8) }}</span
          >

          <CButton
            v-if="item.isInstallChainCodeLoading"
            size="sm"
            class="p-0 m-3"
            @click="goToTopic(installChainCodeTopicId)"
          >
            <fa-icon icon="sync" size="lg" class="animated" />
          </CButton>
          <CButton
            v-else
            size="sm"
            class="p-0 m-3"
            @click="installChainCodeModal($event, item)"
            v-c-tooltip="{
              visible: true,
              content: $t('smart.installSm'),
              placement: 'top',
            }"
          >
            <fa-icon icon="download" size="lg" />
          </CButton>

          <div class="card-header-actions">
            <CButton
              size="sm"
              class="p-0 ml-3 mr-3 self-align-right"
              @click="exportPackage($event, item)"
              v-c-tooltip="{
                visible: true,
                content: $t('smart.exportSm'),
                placement: 'top',
              }"
            >
              <CIcon name="cil-cloud-download" size="sm" />
            </CButton>
            <CButton
              size="sm"
              class="p-0 ml-3 mr-4 self-align-right"
              @click="deletePackage($event, item.id)"
              v-c-tooltip="{
                visible: true,
                content: $t('smart.removePackage'),
                placement: 'top',
              }"
            >
              <CIcon name="cil-trash" size="sm" />
            </CButton>
            <CButton size="sm" class="p-0 m-0 self-align-right">
              <fa-icon icon="angle-up" size="lg" v-if="accordion === i" />
              <fa-icon icon="angle-down" size="lg" v-else />
            </CButton>
          </div>
        </template>
      </CCardHeader>

      <CCollapse :show="accordion === i">
        <CCardBody class="border-top">
          <CDataTable
            v-if="item.instances && item.instances.length > 0"
            striped
            :items="item.instances"
            :fields="fieldsForTable"
            :items-per-page="100"
            responsive
          >
            <template #build_num="item">
              <td>
                <div class="instanceHeight">
                  {{ item.item.build_num }}
                </div>
              </td>
            </template>

            <template #channel_name="item">
              <td>
                <div class="instanceHeight">
                  {{ item.item.channel_name }}
                </div>
              </td>
            </template>

            <template #signature_policy="item">
              <td>
                <div class="instanceHeight">
                  <ViewSignatureModal :item="item" />
                </div>
              </td>
            </template>

            <template #actions="item">
              <td class="align-right-block">
                <CButton
                  v-if="item.item.isApproveChainCodeLoading"
                  size="sm"
                  class="p-0 m-3"
                  @click="goToTopic(approveChainCodeTopicId)"
                >
                  <fa-icon icon="sync" size="lg" class="animated" />
                </CButton>

                <CButton
                  v-else
                  size="sm"
                  class="p-0 m-3"
                  @click="approveChainCodeModal(item)"
                  v-c-tooltip="{
                    visible: true,
                    content: $t('smart.approveSm'),
                    placement: 'left',
                    container: 'body',
                  }"
                >
                  <fa-icon icon="thumbs-up" size="lg" />
                </CButton>

                <CommitModal :item="item" />
              </td>
            </template>
          </CDataTable>
          <CButton
            variant="outline"
            color="dark"
            class="mt-3"
            @click="() => (isCreateInstanceModalShowing = true)"
          >
            <fa-icon icon="database" size="lg" />
            {{ $t("smart.createExample") }}
          </CButton>
          <CreateInstanceModal
            :item="item"
            v-if="isCreateInstanceModalShowing"
            :show="isCreateInstanceModalShowing"
            @close="() => (isCreateInstanceModalShowing = false)"
          />
        </CCardBody>
      </CCollapse>
    </CCard>

    <ApproveChainCodeModal
      :isShowModal="isApproveChainCodeModal"
      :ordererList="modalParam.ordererList"
      :fabricAdminToolList="modalParam.fabricAdminToolList"
      :peerList="modalParam.peerList"
      :id="approveId"
      :packageId="packageId"
      :type="modalType"
      @closeModal="onCloseModal"
      @createParam="approveChainCode"
    />
  </div>
</template>

<script>
import utilMessages from "@/utils/messages";
import ApproveChainCodeModal from "./ApproveChainCodeModal";
import ViewSignatureModal from "./ViewSignatureModal";
import CommitModal from "./CommitModal";
import CreateInstanceModal from "./CreateInstanceModal";
import jwt_decode from "jwt-decode";
import moment from "moment";

export default {
  components: {
    ApproveChainCodeModal,
    CreateInstanceModal,
    CommitModal,
    ViewSignatureModal,
  },
  name: "Accordion",
  data() {
    return {
      isApproveChainCodeModal: false,
      isInstallChainCodeModal: false,
      isShowCreateBuild: false,
      isCreateInstanceModal: false,
      approveChainCodeTopicId: null,
      commitChainCodeTopicId: null,
      installChainCodeTopicId: null,
      accordion: false,
      fieldsForTable: [
        { key: "build_num", label: "Sequence", _classes: "font-weight-bold" },
        { key: "channel_name", label: this.$t("smart.channel") },
        { key: "signature_policy", label: this.$t("smart.endorsement") },
        { key: "actions", label: "" },
      ],
      modalParam: {
        ordererList: [],
        fabricAdminToolList: [],
        peerList: [],
        channelList: [],
      },
      bNetID: null,
      approveId: null,
      packageId: null,
      modalType: "",
      isCreateInstanceModalShowing: false,
    };
  },
  props: {
    list: Array,
    isKnown: Boolean,
  },
  async mounted() {},
  computed: {},
  methods: {
    accordeonHandler(i) {
      this.accordion = this.accordion === i ? false : i;
    },
    async exportPackage(event, item) {
      event.stopPropagation();
      if (this.$route.params.id) {
        this.smartContractId = this.$route.params.id;
        const name = `${item.code_name}_${item.version}_${moment(
          item.build_datetime
        ).format("YYYYMMDD-HHmmss")}_${item.package_hash.substring(0, 7)}.hbx`;
        try {
          const data = {
            packageId: item.id,
            smartContractId: this.smartContractId,
            name,
          };
          await this.$store.dispatch("exportPackage", data);
        } catch (err) {
          this.$toast.error(utilMessages.errMessage(err));
        }
      }
    },
    async deletePackage(event, packageId) {
      event.stopPropagation();
      if (this.$route.params.id) {
        this.smartContractId = this.$route.params.id;
        try {
          const data = {
            packageId,
            smartContractId: this.smartContractId,
          };
          await this.$store.dispatch("deletePackage", data);
        } catch (err) {
          this.$toast.error(utilMessages.errMessage(err));
        }
      }
    },
    async getOrderer() {
      try {
        const param = `blockchain_net_id=${this.bNetID}&type=orderer`;
        this.modalParam.ordererList = await this.$store.dispatch(
          "fetchEndpoints",
          param
        );
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async getFabricAdminTool() {
      try {
        const param = `status=attached&current_org=true&type=fabric-admin-tools`;
        this.modalParam.fabricAdminToolList = await this.$store.dispatch(
          "fetchEndpoints",
          param
        );
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async getPeer() {
      try {
        const param = `type=peer&current_org=true`;
        this.modalParam.peerList = await this.$store.dispatch(
          "fetchEndpoints",
          param
        );
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async approveChainCodeModal(item) {
      await this.getBlockChainId();
      await this.getFabricAdminTool();
      await this.getOrderer();
      await this.getPeer();
      this.packageId = item.item.smart_contract_package_id;
      this.approveId = item.item.id;
      this.modalType = "approve";
      this.isApproveChainCodeModal = true;
    },
    async getBlockChainId() {
      if (!this.bNetID) {
        const token = localStorage.getItem("token");
        const decodeToken = jwt_decode(token);
        const { blockchain_net_id } = decodeToken;
        this.bNetID = blockchain_net_id;
      }
    },
    async approveChainCode(values) {
      const data = {
        packageId: this.packageId,
        vm: this,
        ...values,
      };
      try {
        if (values.type === "approve") {
          this.approveChainCodeTopicId = await this.$store.dispatch(
            "approveChaincode",
            data
          );
        } else if (values.type === "commit") {
          this.commitChainCodeTopicId = await this.$store.dispatch(
            "commitChaincode",
            data
          );
        } else {
          this.installChainCodeTopicId = await this.$store.dispatch(
            "installChaincode",
            data
          );
        }
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async installChainCodeModal(event, item) {
      event.stopPropagation();
      await this.getBlockChainId();
      await this.getFabricAdminTool();
      await this.getPeer();
      this.packageId = item.id;
      this.approveId = item.id;
      this.modalType = "install";
      this.isApproveChainCodeModal = true;
    },
    onCloseModal() {
      this.isApproveChainCodeModal = false;
    },
    goToTopic(topic_id) {
      window.open(`/topics/${topic_id}`);
    },
    getDateFormatted(dt) {
      if (dt) return moment(dt).format("DD.MM.YYYY HH:mm:ss Z");
    },
    async showCreateInstanceModal() {
      this.isCreateInstanceModal = true;
    },
  },
};
</script>
<style>
.collapse_item_header {
  cursor: pointer;
}

.collapse_item_header.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) calc(0.25rem - 1px)
    calc(0.25rem - 1px);
}

.animated {
  animation: spin 2s linear infinite;
}

.align-right-block {
  justify-content: flex-end;
  display: flex;
}

.mr-icon {
  margin-right: 10px !important;
}

.instanceHeight {
  display: flex;
  align-items: center;
  height: 48px;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
