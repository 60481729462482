<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->


<template>
  <div class='inlineBox'>
    <CButton variant='outline' color='dark' class='m-3' @click='importPackage'>
      <fa-icon icon='upload' size='lg' />
      {{ $t('smart.importPackage') }}
    </CButton>
    <CModal :title="$t('smart.importPackageItem')" :show.sync='isShowModal' :closeOnBackdrop='false'
      class='hide-close-button'>
      <CRow>
        <CCol>
          <CForm id='importForm'>

            <div role='group' class='form-group form-row'>
              <label class='col-form-label col-sm-3'>{{ $t('smart.fileExt') }}</label>
              <div class='col-sm-9'>
                <b-form-file v-model='form.file' :placeholder="$t('smart.filePl')" lang='ru' class='mb-3' required
                  @change='fileChange' :browse-text="$t('common.browse')">
                </b-form-file>
              </div>
            </div>
          </CForm>
        </CCol>
      </CRow>
      <div slot='footer' class='footer-buttons'>
        <CButton type='button' class='btn btn-secondary' @click='closeModal'> {{ $t('common.cancel') }}
        </CButton>
        <CButton type='submit' @click.prevent='readFile' color='success'>
          {{ $t('smart.import') }}
        </CButton>
      </div>
    </CModal>
  </div>
</template>

<script>
import utilMessages from '@/utils/messages';
import { BFormFile } from 'bootstrap-vue';

export default {
  name: 'ImportBuildModal',
  components: {
    BFormFile
  },
  data() {
    return {
      smartContractId: null,
      form: {
        file: null
      },
      isShowModal: false
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.smartContractId = this.$route.params.id;
    }
  },

  methods: {
    fileChange($event) {
      this.form.file = $event.target.files[0];
    },

    readFile() {
      const { file } = this.form;
      let data = new FormData();
      data.append('files', new Blob([file], { type: 'multipart/form-data' }));

      this.importBuild(data);
    },

    closeModal() {
      this.form = {
        file: null
      };
      this.isShowModal = false;
    },

    async importBuild(data) {
      try {
        await this.$store.dispatch('importBuild', { data: data, id: this.smartContractId });
        this.$toast.success(this.$t('smart.packageImported'));
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
      this.closeModal();
    },
    importPackage() {
      this.isShowModal = true;
    }

  }
};
</script>

<style scoped>
.footer-buttons button {
  margin-left: 5px;
}

.inlineBox {
  display: inline-block;
}
</style>
