<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <CModal
    title=""
    :show="show"
    :closeOnBackdrop="false"
    class="hide-close-button"
    size="xl"
  >
    <div slot="header-wrapper">
      <header class="modal-header">
        <div slot="header" class="header-wrapper modal-flex-current">
          <h5 class="modal-title">{{ $t("smart.instanceParams") }}</h5>
          <CButtonClose @click="closeModal" />
        </div>
      </header>
    </div>

    <div class="bodyWrapper" slot="body-wrapper">
      <div class="hauberk-form-group">
        <div class="hauberk-form-group__title">
          {{ $t("network.commomProperties") }}
        </div>

        <div class="hauberk-form-item hb-item hb-item_small d-flex mb-3">
          <div class="hb-item__label hb-label d-flex flex-column">
            <label class="hb-label__main" for="channel-selector">
              {{ $t("smart.channel") }}*
            </label>
          </div>

          <CSelect
            class="hb-item__input"
            v-if="channelList.length > 0"
            id="channel-selector"
            :options="channelForSelect"
            :horizontal="{ label: 'col-sm-5', input: 'd-flex w-100' }"
            required
            placeholder=" "
            addLabelClasses="font-weight-bold"
            :value.sync="form.channel"
            :isValid="isValid.channel"
            :tooltipFeedback="false"
            :invalidFeedback="validationMessages.required"
            @change="channelHandle"
          />
        </div>

        <div class="hauberk-form-item hb-item hb-item_small d-flex mb-3">
          <div
            class="hb-item__label hb-label d-flex flex-column align-self-start"
          >
            <label class="hb-label__main align-self-start" for="init-params">
              {{ $t("smart.initParams") }}
            </label>
          </div>

          <div class="hb-item__input">
            <div class="switcher d-flex align-items-center mb-1">
              <CSwitch
                id="init-required"
                color="primary"
                class="mr-2"
                @update:checked="onInitRequiredChanged"
              />
              <span>{{ $t("smart.performInitialization") }}</span>
            </div>

            <textarea
              id="init-params"
              class="d-flex w-100 hb-textarea"
              v-model="form.initParams"
              :readonly="!form.initRequired"
            ></textarea>
          </div>
        </div>

        <div class="hauberk-form-item hb-item hb-item_small d-flex">
          <div
            class="hb-item__label hb-label d-flex flex-column align-self-start"
          >
            <label class="hb-label__main" for="pdc-parameters">
              {{ $t("smart.pdcParams") }}
            </label>
          </div>

          <div class="hb-item__input">
            <div class="switcher d-flex align-items-center mb-1">
              <CSwitch
                id="pdc-required"
                color="primary"
                class="mr-2"
                @update:checked="onPdcRequiredChanged"
              />
              <span>{{ $t("smart.addCollectiponOfPrivateData") }}</span>
            </div>
            <textarea
              id="pdc-parameters"
              class="d-flex w-100 hb-textarea"
              v-model="form.pdcParams"
              :readonly="!form.pdcRequired"
            ></textarea>
          </div>
        </div>
      </div>

      <div class="hauberk-form-group">
        <div class="hauberk-form-group__title">
          {{ $t("smart.politics") }}
        </div>

        <div class="hauberk-form-item hb-item hb-item_small d-flex">
          <div
            class="hb-item__label hb-label d-flex flex-column align-self-start"
          >
            <label class="hb-label__main" for="channel-selector">
              {{ $t("smart.policyText") }}
            </label>
          </div>

          <div class="hb-item__input">
            <textarea
              class="d-flex w-100 hb-textarea"
              v-model="form.signaturePolicy"
              readonly
            ></textarea>
          </div>
        </div>

        <div class="hauberk-form-item hb-item hb-item_small d-flex">
          <div
            class="hb-item__label hb-label d-flex flex-column align-self-start"
          >
            <label class="hb-label__main" for="channel-selector">
              {{ $t("smart.rule") }}
            </label>
          </div>

          <div class="selectList hb-item__input">
            <div class="d-flex flex-column w-100">
              <rule-constructor
                v-model="rule"
                :organizations="organizations"
                @changed="updateRule()"
              ></rule-constructor>
              <CButton
                v-if="imageSchemeUrl !== ''"
                class="show-image-button my-2"
                variant="ghost"
                color="primary"
                @click="() => (isImageShowing = !isImageShowing)"
              >
                {{
                  isImageShowing
                    ? $t("smart.hideRuleImage")
                    : $t("smart.showRuleImage")
                }}</CButton
              >
              <CCollapse :show="isImageShowing">
                <img
                  v-if="imageSchemeUrl !== ''"
                  :src="imageSchemeUrl"
                  class="rule-image mb-2"
                />
              </CCollapse>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div slot="footer" class="footer-buttons">
      <CButton type="button" class="btn btn-secondary" @click="closeModal">
        {{ $t("common.cancel") }}
      </CButton>
      <CButton
        type="button"
        class="btn btn-primary"
        :disabled="!isFormValid"
        @click="createParam"
      >
        {{ $t("common.create") }}
      </CButton>
    </div>
  </CModal>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import messages from "../messages";
import plantumlEncoder from "plantuml-encoder";
import RuleConstructor from "./RuleConstructor/RuleConstructor";

export default {
  name: "CreateInstanceModal",
  components: {
    RuleConstructor,
  },
  props: {
    item: Object,
    type: String,
    show: Boolean,
  },
  data() {
    return {
      isCreateInstanceModal: false,
      isShowCondition: false,
      validationMessages: messages.validation,
      signCollection: {},
      validError: false,
      imageSchemeUrl: "",
      form: {
        channel: null,
        signaturePolicy: "",
        initParams: "",
        pdcParams: "",
        initRequired: false,
        pdcRequired: false,
      },
      channelList: [],
      userOrganizations: [],
      rule: {
        operator: "OR",
        conditions: [""],
        children: [],
      },
      isImageShowing: false,
      color: "light",
    };
  },
  async mounted() {
    this.smart_contract_project_id = this.item.smart_contract_project?.id;
    this.channelList = await this.$store.dispatch("getChannelsOrganization");

    const body = document.querySelector("body");
    body.classList.add("scroll-none");
  },
  destroyed() {
    const body = document.querySelector("body");
    body.classList.remove("scroll-none");
  },
  validations: {
    form: {
      channel: {
        required,
      },
      signaturePolicy: {
        required,
      },
    },
  },
  computed: {
    isValid() {
      let channelList;

      if (this.$v.form.channel.$dirty)
        channelList = !this.$v.form.channel.$invalid;

      return {
        channelList,
      };
    },
    isFormValid() {
      return !this.$v.form.$invalid;
    },
    channelForSelect() {
      return this.channelList?.length > 0
        ? this.channelList.map((item) => {
            return {
              label: item.code_name,
              value: item.id,
            };
          })
        : [];
    },
    organizations() {
      if (!this.userOrganizations) {
        return [];
      }

      return this.userOrganizations.map((organization) => ({
        label: organization.mspid,
        value: organization.mspid,
      }));
    },
  },
  methods: {
    async createParam() {
      const data = {
        channel_id: this.form.channel,
        signature_policy: this.form.signaturePolicy,
        smart_contract_package_id: this.item.id,
        init_params: this.form.initParams,
        pdc_params: this.form.pdcParams,
        init_required: this.form.initRequired,
        pdc_required: this.form.pdcRequired
      };
      await this.createInstance(data);
      this.closeModal();
    },
    channelHandle(e) {
      const { value } = e.target;
      this.form.channel = value;
      this.getOrgByChannel(value);
    },
    async getOrgByChannel(channelId) {
      const channel = await this.$store.dispatch("fetchChannel", channelId);

      if (!channel?.blockchain_net_id) {
        return;
      }

      const organizations = await this.$store.dispatch(
        "getOrgByBlockchainAndChannel",
        {
          bchId: channel?.blockchain_net_id,
        }
      );

      this.userOrganizations = Array.isArray(organizations)
        ? organizations?.filter((e) => e.mspid && e.mspid !== "")
        : [];
    },
    async createInstance(data) {
      await this.$store.dispatch("createInstance", {
        ...data,
        id: this.smart_contract_project_id,
        vm: this,
      });
    },
    async showPlantUml() {
      const signaturePolicyExpression = this.form.signaturePolicy;
      const encoded = plantumlEncoder.encode(
        `@startjson\n{${this.convertToJson(
          signaturePolicyExpression
        )}}\n@endjson`
      );
      const response = await this.$store.dispatch(
        "getUmlForEndorsementView",
        encoded
      );

      if (!response) {
        return;
      }

      try {
        this.imageSchemeUrl = (window.URL || window.webkitURL).createObjectURL(
          new Blob([response])
        );
      } catch (e) {
        console.error(e);
      }
    },
    convertToJson(str) {
      const regexps = [
        { expression: /\(/g, replaceWith: ":{" },
        { expression: /\)/g, replaceWith: "}" },
        { expression: /'/g, replaceWith: '"' },
        { expression: /.peer"/g, replaceWith: '_peer": ""' },
        { expression: /OR/g, replaceWith: '"OR"' },
        { expression: /AND/g, replaceWith: '"AND"' },
      ];

      return regexps.reduce(
        (prev, item) => prev.replace(item.expression, item.replaceWith),
        str
      );
    },
    getDiagramImage(img) {
      return img.replace("/uml/", "/png/");
    },
    updateRule() {
      this.form.signaturePolicy = `${this.generateRule(this.rule)}`;
      this.showPlantUml();
    },
    generateRule(rule) {
      const base = rule.conditions.map((item) => `'${item}.peer'`).join(", ");

      if (!rule.children || rule.children.length === 0) {
        return `${rule.operator}(${base})`;
      }

      const childrenStr = rule.children
        .map((item) => this.generateRule(item))
        .join(", ");

      return `${rule.operator}(${base}, ${childrenStr})`;
    },
    closeModal() {
      this.form = {
        channel: null,
        signaturePolicy: "",
        generateSelects: [],
      };
      this.rule = {
        operator: "OR",
        conditions: [""],
        children: [],
      };
      this.userOrganizations = [];
      this.signCollection = {};
      this.imageSchemeUrl = "";
      this.isImageShowing = false;

      this.$emit("close");
    },
    onInitRequiredChanged(newValue) {
      this.form.initRequired = newValue;
    },
    onPdcRequiredChanged(newValue) {
      this.form.pdcRequired = newValue;
    },
  },
};
</script>

<style scoped>
.footer-buttons button {
  margin-left: 5px;
}

.bodyWrapper {
  overflow: auto;
  padding: 20px;
}

.minWidthSelect {
  min-width: 25%;
}

.selectList {
  padding-bottom: 20px;
}

.selectList,
.doubleSelectList {
  display: flex;
  min-width: 25%;
  justify-content: flex-start;
}

.doubleSelectList > div {
  width: 100%;
}

.channelSelect {
}

.wallpaper {
  display: flex;
  align-items: center;
  padding: 0;
}

.wallpaper svg {
  margin-right: 5px;
}

.modal-flex-current {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

ul {
  list-style: none;
}

.rule-image {
  image-rendering: smooth;
  margin-right: auto;
}

.show-image-button {
  margin-left: auto;
}
</style>
