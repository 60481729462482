<template>
  <div
    class="hauberk-select"
    @click="handleClick"
    @focusin="handleClick"
    v-click-outside="handleClickOut"
  >
    <div
      class="hauberk-select__input d-flex flex-wrap"
      v-bind:class="{
        input_focused: isFocused,
        'hauberk-select_valid': value.length > 0,
      }"
    >
      <input
        :placeholder="placeholderText"
        v-bind:class="{
          'hauberk-select__input_valid': value.length > 0,
        }"
        class="search-input"
        type="text"
        v-model="searchTerm"
      />
    </div>

    <transition name="fade">
      <div class="haubrek-select__options" v-if="isFocused">
        <ul class="options" v-if="filteredOptions.length > 0">
          <li
            tabindex="0"
            class="options__item"
            v-for="option in filteredOptions"
            :key="option.value"
            v-on:keyup.space="handleOptionClick(option)"
            @click="handleOptionClick(option)"
            v-bind:class="{ options__item_selected: isSelected(option) }"
          >
            {{ option.label }}
          </li>
        </ul>
        <span v-else class="not-items">
          {{ $t("components.hauberkSelect.emptyState") }}
        </span>
      </div>
    </transition>
  </div>
</template>

<script>
import { debounce } from "lodash";

export default {
  name: "HauberkSelect",
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
      required: true,
    },
    placeholder: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      isFocused: false,
      selectedItems: this.value,
      filteredOptions: this.options,
      searchTerm: "",
    };
  },
  methods: {
    handleClick() {
      this.isFocused = true;
    },
    handleClickOut() {
      this.isFocused = false;
    },
    handleOptionClick(option) {
      const newValue = [...this.value];
      const index = newValue.indexOf(option.value);

      if (index < 0) {
        newValue.push(option.value);
      } else {
        newValue.splice(index, 1);
      }

      this.$emit("input", newValue);
    },
    isSelected(option) {
      return this.value.includes(option.value);
    },
    filterOptionsDebounced: debounce(function (searchTerm) {
      this.filteredOptions = this.options.filter((item) =>
        item.label.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }, 500),
  },
  watch: {
    searchTerm: {
      handler(searchTerm) {
        this.filterOptionsDebounced(searchTerm);
      },
      immediate: true,
    },
    options: function () {
      this.filterOptionsDebounced("");
    },
  },
  computed: {
    placeholderText() {
      return this.value && this.value.length > 0
        ? `${this.$i18n.t("components.hauberkSelect.selectedItems")}: ${
            this.value.length
          }`
        : this.placeholder || this.$i18n.t("components.hauberkSelect.selectAnItem");
    },
  },
};
</script>

<style scoped>
.hauberk-select {
  width: 100%;
  position: relative;
  cursor: pointer;
  font-size: 0.875rem;
  user-select: none;
}

.hauberk-select__input {
  width: 100%;
  line-height: 1.5;
  vertical-align: middle;
  border-radius: 0.25rem;
  border: 1px solid #d8dbe0;
  position: inline-block;
  padding: 0.375rem 0 0.375rem 0.75rem;
  height: calc(1.5em + 0.75rem + 2px);
  box-sizing: border-box;
  color: #768192;
}

.hauberk-select_valid {
  border-color: #2eb85c;
}

.hauberk-select__input_valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%232eb85c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  background-position: right calc(1.3em) center !important;
}

.input_focused {
  background-color: #fff;
  border-color: #958bef;
  box-shadow: 0 0 0 0.2rem rgb(50, 31, 219, 25%);
}

.haubrek-select__options {
  position: absolute;
  top: 100%;
  margin-top: 0.65rem;

  background-color: #fff;
  border-radius: 0.25rem;
  border: 1px solid #d8dbe0;
  padding: 0.5rem 0.75rem;
  width: 100%;

  z-index: 1000;

  max-height: 300px;
  overflow-y: auto;
}

.options {
  margin: 0;
  padding: 0;
  list-style: none;
}

.options__item {
  text-decoration: none;
  color: #455164;
  box-sizing: border-box;
  padding: 0.5rem 1.25rem 0.5rem 2.25rem;
  vertical-align: middle;
  position: relative;
  border-radius: 0.25rem;
  margin-bottom: 2px;

  user-select: none;
}

.options__item_selected {
  background-color: #ebedef;
}

.options__item:hover {
  background-color: #ebedef;
}

.options__item::before {
  position: absolute;
  top: 0.7rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #9da5b1;
  border-radius: 0.25rem;
  left: 0.625rem;
}
.options__item_selected::before {
  color: #fff;
  border-color: #321fdb;
  background-color: #321fdb;
}

.options__item::after {
  position: absolute;
  top: 0.7rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
  left: 0.625rem;
}

.options__item_selected::after {
  background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc4JyBoZWlnaHQ9JzgnPjxwYXRoIGZpbGw9JyNmZmYnIGQ9J002LjU2NC43NWwtMy41OSAzLjYxMi0xLjUzOC0xLjU1TDAgNC4yNmwyLjk3NCAyLjk5TDggMi4xOTN6Jy8+PC9zdmc+);
}

.token {
  display: inline-block;
  height: 25px;
  padding: 0 0.5rem;
  margin: 2px;
  border-radius: 0.25rem;
  background-color: #ebedef;
  border: 1px solid #d8dbe0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.search-input {
  outline: 0;
  border: 0;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  appearance: none;
  color: #768192;
  background-color: #fff;
  margin: 0;
  padding-right: 2.3rem;

  flex-grow: 1;
  flex-basis: 2em;
  min-width: 2em;
  max-width: 100%;
}

.not-items {
  width: 100%;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
}
</style>
