<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->


<template>
  <CRow>
    <CCol>
      <CCard v-if='smartContract'>
        <CCardHeader>
          <div class='row'>
            <div class='col-sm'>
              <strong>{{ getCapitalize(smartContract.name) }}</strong>
            </div>
            <div class='col-sm'>
              <div class='float-right'>
                <CButton
                  color='gray'
                  size='sm'
                  @click='toChainSource'
                >
                  <fa-icon
                    icon='code'
                    size='1x'
                  />
                  {{ $t('smart.review') }}
                </CButton>
                <CButton
                  v-if='!smartContract.is_imported'
                  color='gray'
                  size='sm'
                  @click='gitPull'
                  :disabled='isGitPullLoading'
                >
                  <fa-icon
                    v-if='isGitPullLoading'
                    icon='sync'
                    size='1x'
                    class='animated'
                    style='color: black'
                  />
                  <CIcon v-else name='cil-cloud-download' size='sm' />
                  {{ $t('smart.gitSync') }}
                </CButton>
              </div>
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CRow class='object-card-row'>
            <CCol sm='3' class='font-weight-bold'>{{ $t('smart.networkType') }}</CCol>
            <CCol sm='9'>
              <img :src='getTypeSrc(smartContract)' style='height:24px' />
              <span class='ml-2'>{{ smartContract.blockchain_version }}</span>
            </CCol>
          </CRow>

          <CRow class='object-card-row'>
            <CCol sm='3' class='font-weight-bold'>{{ $t('smart.langProg') }}</CCol>
            <CCol sm='9'>
              <span class='ml-2'>{{ smartContract.lang || 'Отсутствует' }}</span>
            </CCol>
          </CRow>

          <CRow class='object-card-row'>
            <CCol sm='3' class='font-weight-bold'>git-remote path</CCol>
            <CCol sm='9'>
              <span class='ml-2'>{{ smartContract.git_remote_path }}</span>
            </CCol>
          </CRow>

          <CRow class='object-card-row'>
            <CCol sm='3' class='font-weight-bold'>git-branch</CCol>
            <CCol sm='9'>
              <span class='ml-2'>{{ smartContract.git_branch }}</span>
            </CCol>
          </CRow>

          <CRow class='object-card-row'>
            <CCol sm='3' class='font-weight-bold'>git-local path</CCol>
            <CCol sm='9'>
              <span v-if='!smartContract.is_imported' class='ml-2'>{{ smartContract.git_local_path }}</span>
              <span v-else class='ml-2'>{{ $t('smart.noSourceCode') }}</span>
            </CCol>
          </CRow>

          <CRow class='object-card-row'>
            <CCol sm='3' class='font-weight-bold'>{{ $t('smart.currentStatus') }}</CCol>
            <CCol sm='9'>
              <CBadge class='ml-2' :color='getColorBadge(contractStatus, smartContract)'>
                {{ getBadgeText(contractStatus, smartContract) }}
              </CBadge>
            </CCol>
          </CRow>

          <CRow class='object-card-row'>
            <CCol sm='3' class='font-weight-bold'>{{ $t('smart.currentCommit') }}</CCol>
            <CCol sm='9'>
              <span class='ml-2'>
                {{ getShortCommit() }} {{ $t('smart.from') }} {{ getDateFormatted(smartContract.last_update_datetime) }}
              </span>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>

      <Accordion
        :list='smartContract.packages'
        :isKnown='true'
      />

      <CreateBuildModal :isImported='smartContract.is_imported' />

      <ImportBuildModal />

    </CCol>
  </CRow>
</template>

<script>
import utilMessages from '@/utils/messages';
import { mapGetters } from 'vuex';
import { NetworkTypes } from '../networks/constants';
import Accordion from './Accordion';
import CreateBuildModal from './CreateBuildModal';
import ImportBuildModal from './ImportBuildModal';
import moment from 'moment';
import { SmartContractStatus } from './constants';

export default {
  name: 'SmartContract',
  components: {
    Accordion,
    CreateBuildModal,
    ImportBuildModal
  },
  data() {
    return {
      netTypes: NetworkTypes,
      contractStatus: SmartContractStatus
    };
  },

  async mounted() {
    await this.fetchSmartContract();
  },

  computed: {
    ...mapGetters(['smartContract', 'getGitPull']),

    isGitPullLoading() {
      return this.getGitPull?.isLoading;
    }
  },

  methods: {
    getTypeSrc(nType) {
      const netType = nType?.blockchain_version;
      switch (netType) {
        case this.netTypes.GOSMART10.code:
          return this.netTypes.GOSMART10.src;
        case this.netTypes.HLF20.code:
          return this.netTypes.HLF20.src;
        case this.netTypes.HLF14.code:
          return this.netTypes.HLF14.src;
        case this.netTypes.HLF22.code:
          return this.netTypes.HLF22.src;
      }
    },
    getColorBadge(contract, smContract){
      if (contract && smContract){
        return contract[smContract?.update_status]?.style;
      } else {
        return ''
      }
    },
    getBadgeText(status, smContract){
      if (status && smContract) {
        return status[smContract?.update_status]?.text;
      } else {
        return '';
      }

    },
    async fetchSmartContract() {
      if (this.$route.params.id) {
        this.smartContractId = this.$route.params.id;

        try {
          await this.$store.dispatch('getSmartContractById', this.smartContractId);
        } catch (err) {
          this.$toast.error(utilMessages.errMessage(err));
        }
      }
    },
    async gitPull() {
      if (this.$route.params.id) {
        try {
          await this.$store.dispatch('gitPull', this.smartContractId);
        } catch (err) {
          this.$toast.error(utilMessages.errMessage(err));
        }
      }
    },
    getCapitalize(name) {
      return name ? name.substring(0, 1).toUpperCase() + name.substring(1) : '';
    },
    getDateFormatted(dt) {
      if (dt) {
        try {
          //firefox fix
          const localVal = moment.utc(dt, 'YYYY-MM-DD HH:mm:ss Z').local();
          return moment(localVal, 'YYYY-MM-DD HH:mm:ss Z').format('DD.MM.YYYY HH:mm:ss Z');
        } catch (e) {
          console.log(e);
        }

      }
    },
    getShortCommit() {
      if (this.smartContract.last_commit) {
        return this.smartContract.last_commit.substring(0, 8);
      }
    },
    async toChainSource() {
      await this.$router.push({ path: `/smart-contracts/${this.smartContractId}/source-code` });
    }
  }
};
</script>
<style>
.object-card-row {
  margin-bottom: 20px
}

.animated {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg)
  }
}
</style>
